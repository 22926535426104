import * as yup from 'yup';
import { useFieldsConfig } from './form-config/field-config';
import { useMaterialServiceSchema } from './form-config/useMaterialServiceSchema';
import { useWaterAddedSchema } from './form-config/useWaterAddedSchema';
import timestampSchema from './TimestampSchema';
import { useCompanySetup, useVehicleSetup, useVehicleTypeSetup } from './MasterDataProvider';
import React from 'react';
import { find } from 'lodash';

const getShape = config =>
  config.reduce((acc, field) => {
    let initialSchema = yup[field.dataType]();

    // if (field.dataType === FIELD_TYPES.array && field.arrayOf) {
    //   initialSchema = initialSchema.of(prepareValidation(field.arrayOf));
    // }

    let schema;

    if (field.validation) {
      schema = Object.keys(field.validation).reduce((fieldAcc, validationType) => {
        fieldAcc = fieldAcc[validationType](...field.validation[validationType]);
        return fieldAcc;
      }, initialSchema);
    }

    acc[field.id] = schema;

    return acc;
  }, {});

export const useFormConfig = (loadedQuantity, vehicleId) => {
  const materialServiceSchema = useMaterialServiceSchema(loadedQuantity);
  const waterAddedSchema = useWaterAddedSchema(loadedQuantity);
  const { data: company } = useCompanySetup();
  const vehicleSetup = useVehicleSetup();
  const vehicleTypeSetup = useVehicleTypeSetup();

  const vehicleTypeData = React.useMemo(() => {
    const targetVehicle = find(vehicleSetup.data, { id: vehicleId });
    return find(vehicleTypeSetup.data, { id: targetVehicle?.vehicleTypes });
  }, [vehicleSetup.data, vehicleTypeSetup.data, vehicleId]);

  const validationSchema = yup.object().shape({
    lineItems: yup.array().of(materialServiceSchema),
    waterAddedEvents: yup.array().of(waterAddedSchema),
    driverDidNotAdd: yup.boolean(),
    returnConcreteOnTruck: yup.object().when('driverDidNotAdd', {
      is: driverDidNotAdd =>
        !!company?.isReturnedMaterialMandatory &&
        driverDidNotAdd !== true &&
        (!!vehicleTypeData?.isReturnedMaterialInWorkflow || !vehicleTypeData),
      then: yup.object().shape({
        value: yup
          .number()
          .max(loadedQuantity.value, {
            key: 'exceedsLoadedQuantity',
            values: { loadedQuantity: loadedQuantity.value },
          })
          .required('required'),
      }),
      otherwise: yup.object().shape({
        value: yup.number().max(loadedQuantity.value, {
          key: 'exceedsLoadedQuantity',
          values: { loadedQuantity: loadedQuantity.value },
        }),
      }),
    }),
    returnReason: yup.object().when('returnConcreteOnTruck', {
      is: returnConcreteOnTruck => returnConcreteOnTruck?.value > 0,
      then: schema => schema.required('required'),
    }),
    ticketEvents: yup.object().shape({
      PRINTED: timestampSchema('PRINTED'),
      LOADING_STARTED: timestampSchema('LOADING_STARTED'),
      LOADING_COMPLETE: timestampSchema('LOADING_COMPLETE'),
      TO_JOB: timestampSchema('TO_JOB'),
      ARRIVE_JOB: timestampSchema('ARRIVE_JOB'),
      UNLOADING: timestampSchema('UNLOADING'),
      END_UNLOADING: timestampSchema('END_UNLOADING'),
      LEAVE_JOB: timestampSchema('LEAVE_JOB'),
      IN_YARD: timestampSchema('IN_YARD'),
      READY_TO_PUMP: timestampSchema('READY_TO_PUMP'),
      PUMPING_STARTED: timestampSchema('PUMPING_STARTED'),
      PUMPING_COMPLETE: timestampSchema('PUMPING_COMPLETE'),
    }),
  });

  const fields = useFieldsConfig(loadedQuantity);

  return {
    validationSchema,
    fields,
  };
};
