import React from 'react';
import { AddProducts } from './add-products-services/AddProducts';
import { AddWater } from './add-water/AddWater';
import { ReturnedMaterials } from './returned-material/ReturnedMaterials';
import { TicketDetail } from './ticket-detail/TicketDetail';
import { Acceptance } from './acceptance/Acceptance';

export default [
  { id: 'ticketDetail', testId: 'ticketDetail', component: <TicketDetail /> },
  { id: 'water', testId: 'water', component: <AddWater />, path: 'waterAddedEvents' },
  { id: 'materialService', testId: 'materialService', component: <AddProducts />, path: 'lineItems' },
  { id: 'returnedMaterial', testId: 'returnedMaterial', component: <ReturnedMaterials />, path: ['returnConcreteOnTruck', 'returnReason'] },
  { id: 'finalize', testId: 'finalize', component: <Acceptance /> },
];
