import { css } from 'styled-components';

export default css`
  padding: 0;

  .refresh {
    cursor: pointer;
  }

  &.ticket-events {
    table {
      width: 100%;
      border-collapse: collapse;

      th {
        background-color: var(--color-background-simpleGrid-header);
        border: 1px solid var(--color-border-simpleGrid-header);
      }

      tr {
        &.hasError {
          td {
            background-color: #fafab0;
            .error {
              display: inline;
            }
          }
        }
        &.active {
          td {
            background-color: var(--color-background-simpleGrid-highlightedRow);
            color: var(--color-text-simpleGrid-highlightedRow);
          }
        }

        td {
          transition: all 0.3s;
          padding: 0 5px;
          border: 1px solid var(--color-border-simpleGrid-cell);
          background-color: var(--color-background-simpleGrid-cell);
          cursor: ${({ $onRowClick, $rowClickDisabled }) =>
            $onRowClick && !$rowClickDisabled ? 'pointer' : 'inherit'};
          .error {
            color: red;
            display: none;
          }
        }
      }
    }
  }

  .column {
    gap: 0;
    grid-gap: 0;
  }
  .mt-container {
    margin-bottom: 10px;
  }

  // Temporary
  [data-testid='next-button'] {
    margin-left: 20px;
  }
  [data-testid='delivery-information-button'] {
    margin-left: 20px;
  }

  .header {
    display: flex;
    height: 40px;
    margin: 10px;
    border-bottom: 1px solid #ccc;
    align-items: center;
    .left-side {
      flex: 1;
      display: flex;
      flex-direction: column;
      .subtitle {
        display: flex;
        & > * {
          &:first-child {
            margin-right: 5px;
          }
          &:last-child {
            margin-left: 5px;
          }
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      //grid-gap: 20px;
    }
  }
  .footer {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .left-column {
    width: 300px;
    .left {
      height: 100%;
    }
  }
  .right-column {
    //gap: 20px;
    .right {
    }
  }
  .table-section {
    margin: 0 10px;
    table {
      width: 100%;
    }
    th {
      text-align: left;
    }
  }

  .content-row {
    & > * {
      &:first-child {
        margin-right: 10px;
      }
      & > * {
        &:not(:first-child) {
          margin-top: 10px;
        }
      }
    }
  }
`;
