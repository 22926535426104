import cn from 'classnames';
import { Drawer, Form, Layout } from 'connex-cds';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDataStore } from '../../../datastore/DataStore';
import DeliveryInformation from '../../../delivery-information/DeliveryInformation';
import { useCompanySetup } from '../../../MasterDataProvider';
import style from '../../../style';
import Comments from '../../../ticket-details/comments/Comments';
import DriverInstructions from '../../../ticket-details/driver-instructions/DriverInstructions';
import { LoadingUnloadingPoints } from '../../../ticket-details/logistical-info/loading-unloading-points/LoadingUnloadingPoints';
import { MaterialSummary } from '../../../ticket-details/logistical-info/material-summary/MaterialSummary';
import StatusTimes from '../../../ticket-details/logistical-info/StatusTimes';
import { useTicketContext } from '../../../TicketContext';
import UnloadingMethodForm from '../../../UnloadingMethodForm';

const { Column, Row, Container } = Layout;

const Styled = styled(Column)`
  ${style}
`;

export const TicketDetail = () => {
  const { entityRef } = useParams();
  const { openDrawer } = Drawer.useDrawerContext();
  const { validateForm, values, resetForm } = Form.useFormContext();
  const { isOnline } = useDataStore();
  const { ticket, fetchedDateTime, refreshTicket, refreshing } = useTicketContext();

  const companySetup = useCompanySetup();

  React.useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleRefreshClick = React.useCallback(() => {
    refreshTicket(entityRef);
  }, [entityRef, refreshTicket]);

  const handleDeliveryInfoClick = React.useCallback(() => {
    openDrawer({
      titleStringId: 'deliveryInformation',
      component: <DeliveryInformation data={values} />,
    });
  }, [values, openDrawer]);

  React.useEffect(() => {
    if (ticket === null) {
      resetForm({ reinitialize: true });
    }
  }, [resetForm, ticket]);

  return (
    <Styled className={cn('layout')}>
      <Column>
        <Row flex={1} style={{ padding: '10px', gap: '0' }} className="content-row">
          <Column flex={1} className="">
            <MaterialSummary />
            <LoadingUnloadingPoints data={values} />
            {companySetup?.data?.isDriverCommentEnabled && (
              <Container style={{ padding: '15px' }}>
                <Comments />
              </Container>
            )}
            {ticket?.customData?.ticketType !== 'PMP' && (
              <Container style={{ padding: '10px', paddingBottom: '5px' }}>
                <UnloadingMethodForm />
              </Container>
            )}
          </Column>
          <Column flex={1}>
            <StatusTimes />
            <Container style={{ padding: '10px', flex: '1' }}>
              <DriverInstructions data={values} />
            </Container>
          </Column>
        </Row>
      </Column>
    </Styled>
  );
};
