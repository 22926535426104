import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';

const Styled = styled.div`
  &&& {
    .overview {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      position: relative;

      .step {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #1890ff;
        position: relative;
        cursor: pointer;
        padding: 0 10px;
        transition: background-color 0.3s;
        &.active {
          color: #1890ff;
          .label {
            text-decoration: underline;
          }
          .icon {
          }
        }

        .label {
          font-weight: 500;
        }

        &:not(.active) {
          &:not(.disabled) {
            &.ok {
              color: #187c18;
            }

            &.mandatory {
              color: #bb9a1c;
            }

            &.error {
              color: red;
            }

            &.default {
              color: #aaa;
            }
          }
        }

        &.disabled {
          cursor: not-allowed;
          color: #aaa;
          .label {
            font-style: italic;
            font-weight: 300;
          }
        }

        .icon {
          height: 35px;
          font-size: 1.5em;
          display: flex;
          align-items: center;
          background-color: var(--color-background-page);
          transition: all 0.3s;
          padding: 0 10px;
          z-index: 1;
        }

        &.default {
          .icon {
            font-size: 0.8em;
          }
        }
      }

      & > * {
        &:first-child {
          :after {
            position: absolute;
            top: 16px;
            left: 50%;
            display: block;
            width: 9999px;
            height: 2px;
            content: '';
            background-color: #ccc;
          }
        }
      }

      & > * {
        &:last-child {
          .icon {
            padding-right: 1000px;
            margin-right: -1000px;
          }
        }
      }
    }
  }
`;

const icons = {
  default: <i className="fa-solid fa-circle" />,
  ok: <i className="fa-light fa-circle-check" />,
  mandatory: <i className="fa-light fa-triangle-exclamation" />,
  error: <i className="fa-light fa-circle-xmark" />,
  disabled: <i className="fa-light fa-circle" />,
};

export const STEP_STATUS = {
  DEFAULT: 'default',
  OK: 'ok',
  MANDATORY: 'mandatory',
  ERROR: 'error',
};

export const WizardSteps = ({ steps, currentStep, onClick, compact }) => {
  const handleStepClick = React.useCallback(
    step => {
      if (step.disabled) return;

      onClick(step.id);
    },
    [onClick]
  );

  return (
    <Styled className={cn('wizard')}>
      <div className="overview">
        {steps.map(step => {
          return (
            <div
              className={cn('step', step.status, {
                disabled: step.disabled,
                active: currentStep === step.id,
              })}
              onClick={() => handleStepClick(step)}
            >
              <div data-testid={`${step.testId}-pg-nav-icon`} className="icon">
                {step.disabled && step.status !== STEP_STATUS.MANDATORY ? icons.disabled : icons[step.status]}
              </div>
              <div data-testid={`${step.testId}-pg-nav-label`} className="label">
                {step.title}
              </div>
            </div>
          );
        })}
      </div>
    </Styled>
  );
};
