import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { ProductsServices } from './ProductsServices';
import { ReturnedMaterial } from './ReturnedMaterial';
import style from './style';
import { Layout, Localization, Typography } from 'connex-cds';
import { WaterAdd } from './WaterAdd';

const Styled = styled(Layout.Column)`
  ${style}
`;

export const Activity = () => {
  return (
    <Styled className={cn('activity')}>
      <Layout.Row flex={1}>
        <Layout.Column>
          <Layout.Container flex={1} style={{ padding: '5px 10px' }}>
            <div className="activity-detail">
              <table width="100%">
                <thead>
                  <tr>
                    <th>
                      <Localization.Translate stringId="activity" data-testid="activity-header-text" />
                    </th>
                    <th>
                      <Localization.Translate stringId="quantity" data-testid="quantity-header-text" />
                    </th>
                    <th>
                      <Localization.Translate stringId="onBoard" data-testid="onBoard-header-text" />
                    </th>
                    <th>
                      <Localization.Translate stringId="reason" data-testid="reason-header-text" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <WaterAdd />
                  <ProductsServices />
                  <ReturnedMaterial />
                </tbody>
              </table>
            </div>
          </Layout.Container>
        </Layout.Column>
      </Layout.Row>
    </Styled>
  );
};
