import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem, Core } from 'connex-cds';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';
import { GenericErrorBoundary } from './GenericErrorBoundary';
import messages from './i18n';

API.setSource('mt');

const ONE_HOUR = 1000 * 60 * 60;
const TWELVE_HOURS = ONE_HOUR * 12;

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000, cacheTime: TWELVE_HOURS } },
});

const App = () => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);

  const handleMessageEvent = React.useCallback(event => {
    // console.log("mt rec'd", event?.data?.payload);
  }, []);

  React.useEffect(() => {
    window.addEventListener('message', handleMessageEvent);
    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, [handleMessageEvent]);

  React.useEffect(() => {
    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  return (
    <GenericErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Core.Spinner spin={isConfiguring}>
          <BrowserRouter>
            <ConnexDesignSystem messages={messages}  appId="mt">
              <AppRoutes />
            </ConnexDesignSystem>
          </BrowserRouter>
        </Core.Spinner>
      </QueryClientProvider>
    </GenericErrorBoundary>
  );
};

export default App;
