import cn from 'classnames';
import { Drawer, Form, Localization } from 'connex-cds';
import { filter, map, sortBy } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDataStore } from '../../datastore/DataStore';
import { useCompanySetup } from '../../MasterDataProvider';
import style from '../../style';
import { useTicketContext } from '../../TicketContext';
import EditStatusTime from './EditStatusTime';

const { Time, useTranslateObjects, Translate } = Localization;
const { useDrawerContext } = Drawer;

const Styled = styled.div`
  ${style}
`;

const columns = [
  { path: 'eventTypeTranslated', nameStringId: 'eventStatus' },
  { path: 'eventDateTime', nameStringId: 'time', cellRenderer: row => <Time date={row.eventDateTime} /> },
];

const testId = 'statusTimesGrid';

const StatusTimes = () => {
  const { entityRef } = useParams();
  const { ticket, refreshTicket, refreshing } = useTicketContext();
  const { values, errors } = Form.useFormContext();
  const { isOnline } = useDataStore();
  const companySetup = useCompanySetup();

  const ticketEvents = React.useMemo(
    () => map(ticket?.ticketEvents, (value, key) => ({ eventType: key, ...value })),
    [ticket?.ticketEvents]
  );

  const filteredEvents = filter(ticketEvents, event => {
    switch (event?.eventType) {
      case 'PRINTED':
        if (!companySetup?.data?.hidePrintedEvent) return true;
        break;
      case 'LOADING_STARTED':
        if (!companySetup?.data?.hideLoadingStartedEvent) return true;
        break;
      case 'LOADING_COMPLETE':
        if (!companySetup?.data?.hideLoadingCompleteEvent) return true;
        break;
      case 'TO_JOB':
        if (!companySetup?.data?.hideToJobEvent) return true;
        break;
      case 'ARRIVE_JOB':
        if (!companySetup?.data?.hideArriveJobEvent) return true;
        break;
      case 'UNLOADING':
        if (!companySetup?.data?.hideUnloadingEvent) return true;
        break;
      case 'END_UNLOADING':
        if (!companySetup?.data?.hideEndUnloadingEvent) return true;
        break;
      case 'LEAVE_JOB':
        if (!companySetup?.data?.hideLeaveJobEvent) return true;
        break;
      case 'IN_YARD':
        if (!companySetup?.data?.hideInYardEvent) return true;
        break;
      case 'PUMPING_STARTED':
        if (!companySetup?.data?.hidePumpingStartedEvent) return true;
        break;
      case 'PUMPING_COMPLETE':
        if (!companySetup?.data?.hidePumpingCompleteEvent) return true;
        break;
      case 'READY_TO_PUMP':
        if (!companySetup?.data?.hideReadyToPumpEvent) return true;
        break;
      default:
        return false;
    }
  });

  if (ticket?.customData?.productDetails?.wcContactDateTime && !companySetup?.data?.hideFirstWaterCementContact) {
    const wCContact = {
      eventType: 'FIRST_WCCONTACT',
      eventDateTime: ticket?.customData?.productDetails?.wcContactDateTime,
    };
    filteredEvents.push(wCContact);
  }

  const sortedEvents = sortBy(filteredEvents, event => {
    return values?.ticketEvents?.[event.eventType]?.eventDateTime || event.eventDateTime;
  });
  const { translateObjects } = useTranslateObjects();
  const translatedTicketEvents = translateObjects(sortedEvents, {
    getPath: 'eventType',
    setPath: 'eventTypeTranslated',
  });

  const { openDrawer } = useDrawerContext();

  const handleRowEdit = React.useCallback(
    row => {
      openDrawer({ titleStringId: 'updateStatusTime', component: <EditStatusTime statusType={row?.eventType} /> });
    },
    [openDrawer]
  );

  const isRowEditable = React.useCallback(
    row => {
      switch (row?.eventType) {
        case 'PRINTED':
          if (companySetup?.data?.isPrintedEditable) return true;
          break;
        case 'LOADING_STARTED':
          if (companySetup?.data?.isLoadingStartedEditable) return true;
          break;
        case 'LOADING_COMPLETE':
          if (companySetup?.data?.isLoadingCompleteEditable) return true;
          break;
        case 'TO_JOB':
          if (companySetup?.data?.isToJobEditable) return true;
          break;
        case 'ARRIVE_JOB':
          if (companySetup?.data?.isArriveJobEditable) return true;
          break;
        case 'UNLOADING':
          if (companySetup?.data?.isUnloadingEditable) return true;
          break;
        case 'END_UNLOADING':
          if (companySetup?.data?.isEndUnloadingEditable) return true;
          break;
        case 'LEAVE_JOB':
          if (companySetup?.data?.isLeaveJobEditable) return true;
          break;
        case 'IN_YARD':
          if (companySetup?.data?.isInYardEditable) return true;
          break;
        case 'PUMPING_STARTED':
          if (companySetup?.data?.isPumpingStartedEditable) return true;
          break;
        case 'PUMPING_COMPLETE':
          if (companySetup?.data?.isPumpingCompleteEditable) return true;
          break;
        case 'READY_TO_PUMP':
          if (companySetup?.data?.isReadyToPumpEditable) return true;
          break;
        default:
          return false;
      }
    },
    [
      companySetup?.data?.isArriveJobEditable,
      companySetup?.data?.isEndUnloadingEditable,
      companySetup?.data?.isInYardEditable,
      companySetup?.data?.isLeaveJobEditable,
      companySetup?.data?.isLoadingCompleteEditable,
      companySetup?.data?.isLoadingStartedEditable,
      companySetup?.data?.isPrintedEditable,
      companySetup?.data?.isPumpingCompleteEditable,
      companySetup?.data?.isPumpingStartedEditable,
      companySetup?.data?.isReadyToPumpEditable,
      companySetup?.data?.isToJobEditable,
      companySetup?.data?.isUnloadingEditable,
    ]
  );

  const handleRefreshClick = React.useCallback(() => {
    refreshTicket(entityRef);
  }, [entityRef, refreshTicket]);

  return (
    <Styled className={cn('ticket-events', 'mt-container')}>
      <table data-testid={testId}>
        <thead>
          <tr data-testid={`${testId}-header-row`}>
            <th>
              <Translate stringId="status" data-testid="statusLabel" />
            </th>
            <th>
              <Translate stringId="time" data-testid="timeLabel" />
            </th>
            <th>
              {isOnline && (
                <div onClick={handleRefreshClick} data-testid={'refresh-button'} className="refresh">
                  <i className={`fa fa-refresh mr-2 ${refreshing === true ? 'fa-spin' : ''}`} />
                </div>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {translatedTicketEvents?.map((event, idx) => {
            return (
              <tr
                key={event.eventType}
                data-testid={`${testId}-row-${idx}`}
                className={cn({ hasError: !!errors?.ticketEvents?.[event.eventType] })}
              >
                <td data-testid={`${testId}-row-${idx}-eventTypeTranslated-value`}>
                  {event.eventTypeTranslated || event.eventType}
                  <span className="error">*</span>
                </td>
                <td data-testid={`${testId}-row-${idx}-eventDateTime-value`}>
                  <Time date={values?.ticketEvents?.[event.eventType]?.eventDateTime || event.eventDateTime} />
                </td>
                <td align="center">
                  {isRowEditable(event) && (
                    <div onClick={() => handleRowEdit(event)}>
                      <i className="fa-light fa-pen-to-square" data-testid={`${testId}-row-${idx}-editButton`} />
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Styled>
  );
};

export default StatusTimes;
